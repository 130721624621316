const baseUrl = "https://via.placeholder.com";

export default function dummyImage({
  desktopWidth = 0,
  desktopHeight = 0,
  mobileWidth = 0,
  mobileHeight = 0,
}: {
  desktopWidth?: number;
  desktopHeight?: number;
  mobileWidth?: number;
  mobileHeight?: number;
}) {
  let width = desktopWidth > 0 ? desktopWidth : mobileWidth;
  let height = desktopHeight > 0 ? desktopHeight : mobileHeight;

  return `${baseUrl}/${width}x${height}/DF252A/FFFFFF`;
}
